import { Carousel } from "react-responsive-carousel"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "swiper/css"
import "swiper/css/effect-cards"

import VideoCarousel from "../components/game/VideoCarousel"
const Game = () => {
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   vertical: true,
  //   verticalSwiping: true,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         vertical: false,
  //         verticalSwiping: false
  //       }
  //     }
  //   ]
  // }
  const games = [
    {
      name: "Chained Cars Impossible Stunts",
      downloads: "1,000,000+ downloads",
      imgSrc: "https://play-lh.googleusercontent.com/ACYxgk3N4fUk9-dC7AumHNh1q6g2QF7QCRor6gB1RG_taQOrMiZy0fPHsLvd-xJ9Wzv0=w240-h480-rw",
      link: "https://play.google.com/store/apps/details?id=com.ma.chained.cars.stunts.freegames&pli=1"
    },
    {
      name: "Horse Cart Carriage Simulator",
      downloads: "1,000,000+ downloads",
      imgSrc: "https://play-lh.googleusercontent.com/8lHuNBQHTQBl0MuCqkHi5l5UPv-SYkhw1m_xBgBN0xd2HrbTNj4eNAKbN7FBlaPyjQ=w240-h480-rw",
      link: "https://play.google.com/store/apps/details?id=com.tgs.village.horsecart.transport.simulator3d"
    },
    {
      name: "Jungle Dinosaur Simulator",
      downloads: "500,000+ downloads",
      imgSrc: "https://play-lh.googleusercontent.com/P3LjKXw3TAUNb4W-sIBCNRpEJtM8xBCT4Di_m-E0IHqRXWPnr6kNY1DG3ZqnVGm7gpg=w240-h480-rw",
      link: "https://play.google.com/store/apps/details?id=com.aptech.jungle.dinosaursimulator.dinoworld"
    },
    {
      name: "Swim-Warrior",
      downloads: "500,000+ downloads",
      imgSrc: "https://is1-ssl.mzstatic.com/image/thumb/Purple211/v4/0e/5b/52/0e5b52cd-bfe9-10a8-8434-83d49ca3f42f/AppIcon-0-0-1x_U007emarketing-0-7-0-85-220.png/230x0w.webp",
      link: "https://apps.apple.com/us/app/swim-warrior/id6449373710"
    },
    {
      name: "JUNGLE DINO SIMULATOR 3D",
      downloads: "400,000+ downloads",
      imgSrc: "https://is1-ssl.mzstatic.com/image/thumb/Purple112/v4/94/13/94/9413941e-cd07-d833-f9c7-35309e8c4d71/AppIcon-0-0-1x_U007emarketing-0-7-0-85-220.png/230x0w.webp",
      link: "https://apps.apple.com/us/app/jungle-dino-simulator-3d-2021/id1462755615"
    },
    {
      name: "Grand Black Superhero Panther",
      downloads: "400,000+ downloads",
      imgSrc: "https://store-images.s-microsoft.com/image/apps.31741.14598622000073877.3bab7f53-d2c8-4439-bb2e-b71edc585766.9ed2d8d9-cb15-472b-b611-0a2421af7cc3?mode=scale&q=90&h=300&w=300",
      link: "https://www.microsoft.com/en-us/p/grand-black-superhero-panther-superstar-city-survival/9p405s7sqm62?ocid=pdpshare&activetab=pivot:overviewtab"
    }
  ]
  return (
    <>
      <div className="container mx-auto p-4 pt-6 md:pt-12 flex flex-col md:flex-row justify-between items-start">
        <div className="w-full md:w-1/2 p-4">
          <h1 className="text-4xl md:text-3xl lg:text-4xl xl:text-6xl font-bold text-black mb-8 sm:text-xl xs:text-xl">
          Your Ultimate Guide to Games and Apps on All Platforms
          </h1>
          <p className="text-gray-700 lg:text-base sm:text-sm p-2 rounded mt-4">
          Welcome to AptechMedia&apos;s ultimate showcase of the best games and apps available across all platforms. Whether you&apos;re a casual gamer, a tech enthusiast, or someone looking for the latest productivity tools, we&apos;ve got you covered. Our handpicked selection features top-rated games and innovative apps designed to enhance your experience on any device. From Android and iOS to Windows and Mac, explore a diverse range of titles that provide for all interests and needs. Dive in and discover new favorites that will keep you entertained, informed, and productive.
          </p>
          <button className="mt-4 bg-blue-600 text-white py-2 px-6 rounded">Join Now!</button>
        </div>
        <div className="w-full md:w-1/2 p-4 flex justify-center">
          <VideoCarousel />
        </div>
      </div>
      {/* <div className="clouds h-56"></div> */}
      {/* <div>
        <h1 className="text-4xl md:text-3xl lg:text-4xl xl:text-6xl font-bold text-black sm:text-xl xs:text-xl text-center my-10">Recent Launches</h1>
      </div> */}
      {/* <div className="carousel-container mt-6">
        <Carousel
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          infiniteLoop={true}
          emulateTouch={true}
          centerMode={true}
          centerSlidePercentage={50}
          swipeable={true}
          // autoPlay={true}
          // interval={1000}
        >
          <div className="flex flex-row sm:flex-row xs:flex-col bg-white rounded-3xl justify-between border border-gray-300 transition duration-200 ease-in-out transform hover:-translate-y-1 hover:bg-slate-100 w-card md:w-card sm:w-sm-card xs:w-xs-card">
            <div className="flex flex-col justify-center p-5 sm:items-start xs:items-center">
              <img src="https://www.datocms-assets.com/62418/1663769419-thumbnail_rope_and_demolish.jpeg?fm=webp" alt="Icon" className="!w-16 h-16 mb-2 rounded-2xl" />
              <p className="font-bold text-4xl text-blue-700">Name</p>
              <p className="text-sm">By Sajood</p>
            </div>
            <div className="relative flex justify-center mx-8 mt-8">
              <img src="https://www.voodoo.io/_nuxt/img/iphone-cropped-light.b4a45e0.png" alt="Image" className="w-48 h-auto z-10" />
              <div className="absolute inset-0 flex items-center justify-center overflow-hidden rounded-4xl">
                <video className="w-48 h-auto z-0" autoPlay loop muted>
                  <source src="https://www.datocms-assets.com/62418/1647509643-run-rich-3d.mp4#t=0.001" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
          <div className="flex flex-row sm:flex-row xs:flex-col bg-white rounded-3xl justify-between border border-gray-300 transition duration-200 ease-in-out transform hover:-translate-y-1 hover:bg-slate-100 w-card md:w-card sm:w-sm-card xs:w-xs-card">
            <div className="flex flex-col justify-center p-5 sm:items-start xs:items-center">
              <img src="https://www.datocms-assets.com/62418/1663769419-thumbnail_rope_and_demolish.jpeg?fm=webp" alt="Icon" className="!w-16 h-16 mb-2 rounded-2xl" />
              <p className="font-bold text-4xl text-blue-700">Name</p>
              <p className="text-sm">By Sajood</p>
            </div>
            <div className="relative flex justify-center mx-8 mt-8">
              <img src="https://www.voodoo.io/_nuxt/img/iphone-cropped-light.b4a45e0.png" alt="Image" className="w-48 h-auto z-10" />
              <div className="absolute inset-0 flex items-center justify-center overflow-hidden rounded-4xl">
                <video className="w-48 h-auto z-0" autoPlay loop muted>
                  <source src="https://www.datocms-assets.com/62418/1647509643-run-rich-3d.mp4#t=0.001" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
          <div className="flex flex-row sm:flex-row xs:flex-col bg-white rounded-3xl justify-between border border-gray-300 transition duration-200 ease-in-out transform hover:-translate-y-1 hover:bg-slate-100 w-card md:w-card sm:w-sm-card xs:w-xs-card">
            <div className="flex flex-col justify-center p-5 sm:items-start xs:items-center">
              <img src="https://www.datocms-assets.com/62418/1663769419-thumbnail_rope_and_demolish.jpeg?fm=webp" alt="Icon" className="!w-16 h-16 mb-2 rounded-2xl" />
              <p className="font-bold text-4xl text-blue-700">Name</p>
              <p className="text-sm">By Sajood</p>
            </div>
            <div className="relative flex justify-center mx-8 mt-8">
              <img src="https://www.voodoo.io/_nuxt/img/iphone-cropped-light.b4a45e0.png" alt="Image" className="w-48 h-auto z-10" />
              <div className="absolute inset-0 flex items-center justify-center overflow-hidden rounded-4xl">
                <video className="w-48 h-auto z-0" autoPlay loop muted>
                  <source src="https://www.datocms-assets.com/62418/1647509643-run-rich-3d.mp4#t=0.001" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </Carousel>
      </div> */}
      <div>
        <h1 className="text-4xl md:text-3xl lg:text-4xl xl:text-6xl font-bold text-black sm:text-xl xs:text-xl text-center my-10">Leading the Charts</h1>
        <p className="text-gray-700 lg:text-2xl sm:text-base p-2 rounded mt-4 mb-11 text-center">At AptechMedia, our games don&apos;t just climb the charts—they conquer them. Experience the thrill of top-ranked gaming, where every release is a hit and every player&apos;s victory is celebrated.</p>
      </div>
      <div className="flex flex-wrap justify-evenly">
        <div className="grid grid-cols-2 gap-2 mb-4">
          {games.map((game, index) => (
            <div
              key={index}
              className="rounded-lg p-2 flex items-center hover:bg-blue-50 transition-colors duration-300"
              onClick={() => window.open(game.link, "_blank")}
            >
              <img src={game.imgSrc} alt={`${game.name} Icon`} className="w-20 h-20 mr-6 rounded-2xl" />
              <div>
                <h2 className={"font-bold text-lg break-words"}>{game.name}</h2>
                <p className="text-sm">{game.downloads}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="hidden sm:flex justify-center relative">
          <div className="absolute inset-0 flex items-center justify-center overflow-hidden rounded-4xl">
            <video className="w-60 h-auto z-0 rounded-4xl" autoPlay loop muted>
              <source src="https://www.datocms-assets.com/62418/1647340593-helix-jump.mp4" type="video/mp4" />
            </video>
          </div>
          <img src="https://www.voodoo.io/_nuxt/img/iphone-long-light.f75ebec.png" alt="Phone Image" className="w-64 h-auto z-10" />
        </div>
      </div>
    </>
  )
}

export default Game
