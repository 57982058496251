import { FaTimes } from "react-icons/fa"
import { NavLink } from "react-router-dom"

const Sidebar = ({ isOpen, onClose, onLogout }) => {
  return (
    <>
      <div
        className={`fixed inset-0 z-30 bg-gray-800 bg-opacity-50 transition-opacity lg:hidden ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={onClose}
      ></div>
      <div
        className={`fixed inset-y-0 left-0 z-40 w-64 bg-white shadow-lg transform transition-transform lg:relative lg:translate-x-0 ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="flex items-center justify-between p-4 bg-blue-600 text-white">
          <h2 className="text-xl font-bold">Dashboard</h2>
          <button onClick={onClose} className="lg:hidden">
            <FaTimes />
          </button>
        </div>
        <nav className="flex flex-col p-4">
          <NavLink
            to="/admin/dashboard"
            className={({ isActive }) =>
              isActive
                ? "p-2 text-white bg-blue-600 rounded"
                : "p-2 text-gray-700 hover:bg-gray-200 rounded"
            }
          >
            Jobs
          </NavLink>
          <NavLink
            to="/admin/applicants"
            className={({ isActive }) =>
              isActive
                ? "p-2 text-white bg-blue-600 rounded"
                : "p-2 text-gray-700 hover:bg-gray-200 rounded"
            }
          >
            Applicants
          </NavLink>
          <div className="flex-grow"></div>
          <button
            onClick={onLogout}
            className="mt-4 py-2 px-4 bg-red-600 text-white rounded hover:bg-red-700"
          >
            Logout
          </button>
        </nav>
      </div>
    </>
  )
}

export default Sidebar