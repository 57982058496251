const StudioSection = ({ imageSrc, name, role, description }) => {
  return (
    <div className="flex flex-col sm:flex-row items-center">
      <div className="w-full sm:w-1/2">
        <img src={imageSrc} alt={name} className="rounded-3xl" />
      </div>
      <div className="w-full sm:w-1/2 px-6">
        <p className="text-xl font-semibold">{name}</p>
        <p className="text-sm text-gray-500 mb-12">{role}</p>
        <p className="text-lg text-gray-600">&quot;{description}&quot;</p>
      </div>
    </div>
  )
}

export default StudioSection
