import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { FaBars, FaEye, FaArrowLeft, FaArrowRight, FaEllipsisV } from "react-icons/fa"
import Sidebar from "../components/admin/Sidebar"
import { apiCall } from "../lib/api"
import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"

const BASE_URL = "http://job.aptechmedia.com"

const ThreeDotsMenu = ({ onDelete }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className="relative">
      <button onClick={toggleMenu} className="focus:outline-none">
        <FaEllipsisV className="text-gray-600" />
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-32 bg-white border border-gray-200 rounded shadow-lg z-10">
          <button
            onClick={() => {
              onDelete()
              setIsOpen(false)
            }}
            className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
          >
            Delete
          </button>
        </div>
      )}
    </div>
  )
}

const Applicants = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [applicants, setApplicants] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const navigate = useNavigate()

  const handleLogout = () => {
    localStorage.removeItem("isAuthenticated")
    navigate("/admin/login")
  }

  const fetchApplicants = async (page) => {
    setLoading(true)
    try {
      const response = await apiCall("get", `/api/applicantList?page=${page}`, {}, {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      })
      setApplicants(response.data.data)
      setCurrentPage(response.data.current_page)
      setTotalPages(response.data.last_page)
    } catch (error) {
      console.error("Error fetching applicants:", error)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchApplicants(currentPage)
  }, [currentPage])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleDelete = async (applicant) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to delete this applicant?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const response = await apiCall("delete", `/api/deleteApplicant/${applicant.id}`, {}, {
                Authorization: `Bearer ${localStorage.getItem("token")}`
              })
              if (response.status === 1) {
                fetchApplicants(currentPage)
              } else {
                console.error("Failed to delete applicant:", response.message)
              }
            } catch (error) {
              console.error("Error deleting applicant:", error)
            }
          }
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    })
  }

  const renderPagination = () => {
    const pageNumbers = []
    let startPage = Math.max(1, currentPage - 2)
    let endPage = Math.min(totalPages, currentPage + 2)

    if (currentPage <= 3) {
      endPage = Math.min(5, totalPages)
    }
    if (currentPage >= totalPages - 2) {
      startPage = Math.max(1, totalPages - 4)
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`mx-1 px-3 py-1 rounded ${i === currentPage ? "bg-blue-600 text-white" : "bg-gray-200 text-gray-700"}`}
        >
          {i}
        </button>
      )
    }

    return (
      <div className="flex justify-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          className="mx-1 px-3 py-1 rounded bg-gray-200 text-gray-700"
          disabled={currentPage === 1}
        >
          <FaArrowLeft />
        </button>
        {pageNumbers}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className="mx-1 px-3 py-1 rounded bg-gray-200 text-gray-700"
          disabled={currentPage === totalPages}
        >
          <FaArrowRight />
        </button>
      </div>
    )
  }

  return (
    <div className="min-h-screen flex bg-gray-100">
      <Sidebar
        isOpen={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        onLogout={handleLogout}
      />
      <div className="flex-1 flex flex-col">
        <header className="flex items-center justify-between p-4 bg-white shadow">
          <button onClick={() => setIsSidebarOpen(true)} className="lg:hidden">
            <FaBars />
          </button>
          <h1 className="text-2xl font-bold">Applicants</h1>
        </header>
        <main className="flex-grow p-4">
          <h1 className="text-3xl font-bold mb-4">Applicants</h1>
          {loading ? (
            <div className="flex justify-center items-center">
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
            </div>
          ) : applicants.length === 0 ? (
            <div className="text-center text-gray-500">No applicants found</div>
          ) : (
            <>
              <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow">
                <thead>
                  <tr>
                    <th className="px-4 py-2 border-b">Name</th>
                    <th className="px-4 py-2 border-b">Email</th>
                    <th className="px-4 py-2 border-b">Gender</th>
                    <th className="px-4 py-2 border-b">Contact No</th>
                    <th className="px-4 py-2 border-b">Qualification</th>
                    <th className="px-4 py-2 border-b">CV</th>
                    <th className="px-4 py-2 border-b">Job ID</th>
                    <th className="px-4 py-2 border-b">Position Applied For</th>
                    <th className="px-4 py-2 border-b">Updated At</th>
                    <th className="px-4 py-2 border-b">Created At</th>
                    <th className="px-4 py-2 border-b">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {applicants.map((applicant) => (
                    <tr key={applicant.id}>
                      <td className="px-4 py-2 border-b">{applicant.name}</td>
                      <td className="px-4 py-2 border-b">{applicant.email}</td>
                      <td className="px-4 py-2 border-b">{applicant.gender}</td>
                      <td className="px-4 py-2 border-b">{applicant.contactNo}</td>
                      <td className="px-4 py-2 border-b">{applicant.qualification}</td>
                      <td className="px-4 py-2 border-b">
                        <a href={`${BASE_URL}/${applicant.cv}`} target="_blank" rel="noopener noreferrer">
                          <FaEye className="text-blue-600 cursor-pointer" />
                        </a>
                      </td>
                      <td className="px-4 py-2 border-b">{applicant.jobId}</td>
                      <td className="px-4 py-2 border-b">{applicant.apply_job?.position || "N/A"}</td>
                      <td className="px-4 py-2 border-b">{new Date(applicant.updated_at).toLocaleString()}</td>
                      <td className="px-4 py-2 border-b">{new Date(applicant.created_at).toLocaleString()}</td>
                      <td className="px-4 py-2 border-b">
                        <ThreeDotsMenu onDelete={() => handleDelete(applicant)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {renderPagination()}
            </>
          )}
        </main>
      </div>
    </div>
  )
}

export default Applicants