const AppContent = ({ gradientColor, appimg, appName, appHeading, appDesc, videoSrc }) => {
  return (
    <div className={`bg-gradient-to-r ${gradientColor} rounded-4xl max-w-screen-lg mx-auto p-10 mt-28 mb-28 flex items-center justify-between flex-col lg:flex-row`}>
      <div className="flex flex-col items-center lg:items-start lg:w-1/2">
        <img src={appimg} alt={`${appName} Logo`} className="w-16 h-16 mb-2 rounded-lg" />
        <h2 className="text-6xl font-extrabold mb-10 text-white">{appName}</h2>
        <h3 className="text-2xl font-bold mb-2 text-white">{appHeading}</h3>
        <p className="text-lg text-white">{appDesc}</p>
      </div>
      <div className="flex justify-center h-full relative">
        <img src="https://www.voodoo.io/_nuxt/img/iphone-long-light.f75ebec.png" alt="Phone Image" className="max-w-full h-full z-10" />
        <video className="absolute max-w-full max-h-full object-cover rounded-4xl" autoPlay loop muted>
          <source src={videoSrc} type="video/mp4" />
        </video>
      </div>
    </div>
  )
}

export default AppContent
