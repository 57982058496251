/* eslint-disable comma-dangle */
/* eslint-disable semi */
import axios from "axios";

const BASE_URL = "http://job.aptechmedia.com";

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const apiCall = async (method, url, data = {}, headers = {}) => {
  try {
    const response = await api({
      method,
      url,
      data,
      headers: {
        ...api.defaults.headers,
        ...headers,
      },
    });
    return response.data;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export default api;
