/* eslint-disable react/jsx-key */
import { useState, useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { FaBars, FaPlus, FaEllipsisV, FaArrowLeft, FaArrowRight } from "react-icons/fa"
import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"
import Sidebar from "../components/admin/Sidebar"
import AddJobModal from "../components/admin/AddJobModal"
import EditJobModal from "../components/admin/EditJobModal"
import ApplicantsModal from "../components/admin/ApplicantsModal"
import { apiCall } from "../lib/api"
import { useTable } from "react-table"

const ThreeDotsMenu = ({ onEdit, onDelete, onShowApplicants }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className="relative">
      <button onClick={toggleMenu} className="focus:outline-none">
        <FaEllipsisV className="text-gray-600" />
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-200 rounded shadow-lg z-10">
          <button
            onClick={() => {
              onEdit()
              setIsOpen(false)
            }}
            className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
          >
            Edit
          </button>
          <button
            onClick={() => {
              onDelete()
              setIsOpen(false)
            }}
            className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
          >
            Delete
          </button>
          <button
            onClick={() => {
              onShowApplicants()
              setIsOpen(false)
            }}
            className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
          >
            Show Applicants Applied
          </button>
        </div>
      )}
    </div>
  )
}

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isApplicantsModalOpen, setIsApplicantsModalOpen] = useState(false)
  const [editJobData, setEditJobData] = useState(null)
  const [selectedJobId, setSelectedJobId] = useState(null)
  const [jobs, setJobs] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    fetchJobs(currentPage)
  }, [currentPage])

  const fetchJobs = async (page) => {
    setLoading(true)
    try {
      const response = await apiCall("get", `/api/getJobList?page=${page}`)
      if (response.status === 1) {
        setJobs(response.data.data)
        setTotalPages(response.data.last_page)
      }
    } catch (error) {
      console.error("Failed to fetch jobs:", error)
    } finally {
      setLoading(false)
    }
  }

  const handleLogout = () => {
    localStorage.removeItem("isAuthenticated")
    navigate("/admin/login")
  }

  const openAddModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)
  const openEditModal = (job) => {
    setEditJobData(job)
    setIsEditModalOpen(true)
  }
  const closeEditModal = () => setIsEditModalOpen(false)

  const handleDelete = (job) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to delete this job?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const response = await apiCall("delete", `/api/deleteJob/${job.id}`, {}, {
                Authorization: `Bearer ${localStorage.getItem("token")}`
              })
              if (response.status === 1) {
                fetchJobs(currentPage)
              } else {
                console.error("Failed to delete job:", response.message)
              }
            } catch (error) {
              console.error("Error deleting job:", error)
            }
          }
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    })
  }

  const handleShowApplicants = (job) => {
    setSelectedJobId(job.id)
    setIsApplicantsModalOpen(true)
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const renderPagination = () => {
    const pageNumbers = []
    let startPage = Math.max(1, currentPage - 2)
    let endPage = Math.min(totalPages, currentPage + 2)

    if (currentPage <= 3) {
      endPage = Math.min(5, totalPages)
    }
    if (currentPage >= totalPages - 2) {
      startPage = Math.max(1, totalPages - 4)
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`mx-1 px-3 py-1 rounded ${i === currentPage ? "bg-blue-600 text-white" : "bg-gray-200 text-gray-700"}`}
        >
          {i}
        </button>
      )
    }

    return (
      <div className="flex justify-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          className="mx-1 px-3 py-1 rounded bg-gray-200 text-gray-700"
          disabled={currentPage === 1}
        >
          <FaArrowLeft />
        </button>
        {pageNumbers}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className="mx-1 px-3 py-1 rounded bg-gray-200 text-gray-700"
          disabled={currentPage === totalPages}
        >
          <FaArrowRight />
        </button>
      </div>
    )
  }

  const columns = useMemo(
    () => [
      {
        Header: "Department",
        accessor: "department"
      },
      {
        Header: "Position",
        accessor: "position"
      },
      {
        Header: "Level",
        accessor: "level"
      },
      {
        Header: "Location",
        accessor: "location"
      },
      {
        Header: "Contract",
        accessor: "contract"
      },
      {
        Header: "Posted At",
        accessor: "created_at",
        Cell: ({ value }) => new Date(value).toLocaleDateString()
      },
      {
        Header: "Last Date to Apply",
        accessor: "lastDateToApply",
        Cell: ({ value }) => new Date(value).toLocaleDateString()
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <ThreeDotsMenu
            onEdit={() => openEditModal(row.original)}
            onDelete={() => handleDelete(row.original)}
            onShowApplicants={() => handleShowApplicants(row.original)}
          />
        )
      }
    ],
    []
  )

  const tableInstance = useTable({ columns, data: jobs })

  return (
    <div className="min-h-screen flex bg-gray-100">
      <Sidebar
        isOpen={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        onLogout={handleLogout}
      />
      <div className="flex-1 flex flex-col">
        <header className="flex items-center justify-between p-4 bg-white shadow">
          <button onClick={() => setIsSidebarOpen(true)} className="lg:hidden">
            <FaBars />
          </button>
          <h1 className="text-2xl font-bold">Dashboard</h1>
          <button
            onClick={openAddModal}
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 flex items-center"
          >
            <FaPlus className="mr-2" /> Add Job
          </button>
        </header>
        <main className="flex-grow p-4">
          <h1 className="text-3xl font-bold mb-4">Jobs</h1>
          {loading ? (
            <div className="flex justify-center items-center">
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
            </div>
          ) : jobs.length === 0 ? (
            <div className="text-center text-gray-500">No jobs found</div>
          ) : (
            <>
              <table {...tableInstance.getTableProps()} className="min-w-full bg-white border border-gray-200 rounded-lg shadow">
                <thead>
                  {tableInstance.headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()} className="px-4 py-2 border-b">{column.render("Header")}</th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...tableInstance.getTableBodyProps()}>
                  {tableInstance.rows.map(row => {
                    tableInstance.prepareRow(row)
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => (
                          <td {...cell.getCellProps()} className="px-4 py-2 border-b">{cell.render("Cell")}</td>
                        ))}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {renderPagination()}
            </>
          )}
        </main>
        <AddJobModal isOpen={isModalOpen} onClose={closeModal} />
        {editJobData && (
          <EditJobModal
            isOpen={isEditModalOpen}
            onClose={closeEditModal}
            job={editJobData}
          />
        )}
        {selectedJobId && (
          <ApplicantsModal
            isOpen={isApplicantsModalOpen}
            onClose={() => setIsApplicantsModalOpen(false)}
            jobId={selectedJobId}
          />
        )}
      </div>
    </div>
  )
}

export default Dashboard