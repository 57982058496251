const Testimonals = () => {
  return (
    <>
      <div className="min-w-screen min-h-screen flex items-center justify-center py-5">
        <div className="w-full bg-white px-5 py-8 md:py-12 text-gray-800">
          <div className="w-full max-w-6xl mx-auto">
            <div className="text-center max-w-xl mx-auto">
              <h1 className="text-6xl md:text-7xl font-bold mb-5 text-black">What people <br/>are saying.</h1>
              <h3 className="text-xl mb-5 font-light">Lorem ipsum dolor sit amet consectetur adipisicing elit.</h3>
              <div className="text-center mb-10">
                <span className="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
                <span className="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
                <span className="inline-block w-40 h-1 rounded-full bg-indigo-500"></span>
                <span className="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
                <span className="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
              </div>
            </div>
            <div className="-mx-3 md:flex items-start">
              <div className="px-3 md:w-1/3">
                <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div className="w-full flex mb-4 items-center">
                    <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="https://avatar.iran.liara.run/public/12" alt=""/>
                    </div>
                    <div className="flex-grow pl-3">
                      <h6 className="font-bold text-sm uppercase text-gray-600">Radiantsoft (A Georgia based Startup)</h6>
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="text-sm leading-tight"><span className="text-lg leading-none italic font-bold text-gray-400 mr-1"></span>AptechMedia&apos;s game development prowess is truly remarkable. Their team&apos;s dedication and skill brought Radiantsoft&apos;s vision to life, delivering a product that resonates with ACGN enthusiasts. The seamless collaboration and exceptional end result affirm why they are top-rated on Fiverr. Radiantsoft&apos;s Georgia roots shine through in the game&apos;s engaging experience..
                      <span className="text-lg leading-none italic font-bold text-gray-400 ml-1"></span></p>
                  </div>
                </div>
                <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div className="w-full flex mb-4 items-center">
                    <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="https://avatar.iran.liara.run/public/4" alt=""/>
                    </div>
                    <div className="flex-grow pl-3">
                      <h6 className="font-bold text-sm uppercase text-gray-600">Haymikie (A Client from Singapore)</h6>
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="text-sm leading-tight"><span className="text-lg leading-none italic font-bold text-gray-400 mr-1"></span>It is always great to work with Team AptechMedia, . Their team&apos;s creativity and attention to detail have set a new standard for what we expect in game design. Thanks for the effort and hard work each and every time.<span className="text-lg leading-none italic font-bold text-gray-400 ml-1"></span></p>
                  </div>
                </div>
              </div>
              <div className="px-3 md:w-1/3">
                <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div className="w-full flex mb-4 items-center">
                    <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="https://avatar.iran.liara.run/public/36" alt=""/>
                    </div>
                    <div className="flex-grow pl-3">
                      <h6 className="font-bold text-sm uppercase text-gray-600">Suedocross (A client from USA)</h6>
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="text-sm leading-tight"><span className="text-lg leading-none italic font-bold text-gray-400 mr-1"></span>Team AptechMedia stands out for their exceptional flexibility and communication. They align their schedule with yours, ensuring availability for Zoom conference calls whenever needed. The ease of interaction and clarity in communication make the collaboration process smooth and efficient. Their dedication to client satisfaction is evident, making them highly recommendable. AptechMedia&apos;s commitment to excellence is what sets them apart in the digital service industry.<span className="text-lg leading-none italic font-bold text-gray-400 ml-1"></span></p>
                  </div>
                </div>
                <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div className="w-full flex mb-4 items-center">
                    <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="https://avatar.iran.liara.run/public/31" alt=""/>
                    </div>
                    <div className="flex-grow pl-3">
                      <h6 className="font-bold text-sm uppercase text-gray-600">Mark Friedlander ( A client from USA)</h6>
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="text-sm leading-tight"><span className="text-lg leading-none italic font-bold text-gray-400 mr-1"></span>Approaching Team AptechMedia, I was unsure of what to expect due to my limited experience in this domain. However, their profound knowledge and insightful questioning quickly established a clear understanding of my needs. They skillfully crafted a flow chart that transformed my ideas into a tangible action plan, justifying the investment with clarity. Their expertise not only met but exceeded my expectations, making them a team I can highly recommend for their exceptional service and deliverables. (edited)
                      <span className="text-lg leading-none italic font-bold text-gray-400 ml-1"></span></p>
                  </div>
                </div>
              </div>
              <div className="px-3 md:w-1/3">
                <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div className="w-full flex mb-4 items-center">
                    <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="https://avatar.iran.liara.run/public/18" alt=""/>
                    </div>
                    <div className="flex-grow pl-3">
                      <h6 className="font-bold text-sm uppercase text-gray-600">Ethan (A Malaysian based Client)</h6>
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="text-sm leading-tight"><span className="text-lg leading-none italic font-bold text-gray-400 mr-1"></span>Working with the talented team at AptechMedia has been a remarkable experience. Their patience and dedication throughout the project&apos;s completion were commendable. They provided immediate assistance for any issues that arose, ensuring a smooth workflow. Their development skills are top-notch, making them highly recommendable. I eagerly anticipate collaborating with them on future projects.
                      <span className="text-lg leading-none italic font-bold text-gray-400 ml-1"></span></p>
                  </div>
                </div>
                <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div className="w-full flex mb-4 items-center">
                    <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="https://avatar.iran.liara.run/public/37" alt=""/>
                    </div>
                    <div className="flex-grow pl-3">
                      <h6 className="font-bold text-sm uppercase text-gray-600">Robertran (A Client from Sri Lanka )</h6>
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="text-sm leading-tight"><span className="text-lg leading-none italic font-bold text-gray-400 mr-1"></span>My two-year journey with AptechMedia has been a testament to their cooperative spirit and supportive nature. As a newcomer to the gaming industry, their team has been an invaluable source of knowledge, readily explaining even the queries beyond our immediate project scope. Their willingness to assist at every turn has not only facilitated my learning but also enhanced our collaboration. AptechMedia stands out as a supportive seller, and I can confidently recommend their services to others.<span className="text-lg leading-none italic font-bold text-gray-400 ml-1"></span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Testimonals