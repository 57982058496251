import { useState } from "react"
import { FaChevronDown, FaChevronUp } from "react-icons/fa"

const Privacy = () => {
  const [openSection, setOpenSection] = useState(null)

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section)
  }

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">Privacy Policy</h1>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("policy")}
        >
          {openSection === "policy" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          Privacy Policy
        </h2>
        {openSection === "policy" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
              WE HELP TO CREATE VISUAL STRATEGIES
              <br />
              <br />
              At Aptechmedia, accessible from one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Aptechmedia and how we use it. If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us. This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Aptechmedia. This policy is not applicable to any information collected offline or via channels other than this website.
            </p>
          </div>
        )}
      </div>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("consent")}
        >
          {openSection === "consent" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          Consent
        </h2>
        {openSection === "consent" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
              By using our website, you hereby consent to our Privacy Policy and agree to its terms.
            </p>
          </div>
        )}
      </div>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("information")}
        >
          {openSection === "information" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          Information We Collect
        </h2>
        {openSection === "information" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
              The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information. If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
            </p>
          </div>
        )}
      </div>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("your-information")}
        >
          {openSection === "your-information" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          How we use your information
        </h2>
        {openSection === "your-information" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
            Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the webste, and for marketing and promotional purposes
              <ul className="list-disc list-inside">
                <li>Provide, operate, and maintain our website</li>
                <li>Improve, personalize, and expand our webste</li>
                <li>Understand and analyze how you use our webste</li>
                <li>Develop new products, services, features, and functionality</li>
                <li>Send you emails</li>
                <li>Find and prevent fraud</li>
              </ul>
            </p>
          </div>
        )}
      </div>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("log")}
        >
          {openSection === "log" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          log files
        </h2>
        {openSection === "log" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
            Aptechmedia follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users movement on the website, and gathering demographic information. Our Privacy Policy was created with the help of the Privacy Policy Generator and the Privacy Policy Template.When you register for an Account, we may ask for your contact information, including items such as name, company name, address , email address, and telephone number.
            </p>
          </div>
        )}
      </div>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("third-party")}
        >
          {openSection === "third-party" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          third party privacy policies
        </h2>
        {openSection === "third-party" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
            Aptechmedia&apos;s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links.You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers respective websites. What Are Cookies?
            </p>
          </div>
        )}
      </div>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("child-info")}
        >
          {openSection === "child-info" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          childern&apos;s information
        </h2>
        {openSection === "child-info" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
            Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity. Aptechmedia does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Privacy