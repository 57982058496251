import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/effect-cards"
import { EffectCards, Autoplay } from "swiper/modules"
import video1 from "../../assets/videos_carousel/1.mp4"
import video2 from "../../assets/videos_carousel/2.mp4"
import video3 from "../../assets/videos_carousel/3.mp4"
import video4 from "../../assets/videos_carousel/4.mp4"
import video5 from "../../assets/videos_carousel/5.mp4"

const videos = [video1, video2, video3, video4, video5]

const VideoCarousel = () => {
  return (
    <Swiper
      effect={"cards"}
      grabCursor={true}
      modules={[EffectCards, Autoplay]}
      autoplay={{ delay: 2500, disableOnInteraction: false }}
      className="mySwiper"
    >
      {videos.map((video, index) => (
        <SwiperSlide key={index} className="swiper-slide">
          <video autoPlay loop muted playsInline className="w-full h-full object-cover rounded-lg">
            <source src={video} type="video/mp4" />
          </video>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default VideoCarousel